import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
} from "@radix-ui/react-icons"
import { Button } from "components/Buttonv2"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/SelectV2"

export function DataTablePagination({
    table,
    pageSizeOptions = [10, 20, 30, 40, 50]
}) {
    const totalPages = table.getPageCount()

    const pageArray = totalPages > 0 ? [...Array(totalPages).keys()] : []

    const handleFirstPageClick = () => {
        table.setPageIndex(0);
        table.toggleAllRowsSelected(false); // Reset selected checkboxes
    };

    const handlePreviousPageClick = () => {
        table.previousPage();
        table.toggleAllRowsSelected(false); // Reset selected checkboxes
    };

    const handleNextPageClick = () => {
        table.nextPage();
        table.toggleAllRowsSelected(false); // Reset selected checkboxes
    };

    const handleLastPageClick = () => {
        table.setPageIndex(totalPages - 1);
        table.toggleAllRowsSelected(false); // Reset selected checkboxes
    };


    return (
        <div className="flex w-full flex-col items-center justify-between gap-4 px-3 py-2 sm:flex-row sm:gap-8">
            <p class="sr-only">The above table content will change while interacting with the show entries, Go to Pages, First, Previous, Next, and Last elements.</p>
            {/* <div className="flex-1 whitespace-nowrap text-sm text-muted-foreground">
                {table.getFilteredSelectedRowModel().rows.length} of{" "}
                {table.getFilteredRowModel().rows.length} row(s) selected.
            </div> */}

            <div className="flex items-center space-x-2">

                <label htmlFor='showEntries' className="whitespace-nowrap text-sm font-medium">Show Entries</label>
                <select 
                    id='showEntries' 
                    className="h-8 w-14 border-2 rounded-sm"
                    onChange={(event) => {
                        table.setPageSize(Number(event.target.value))
                    }}
                >
                    {pageSizeOptions.map((pageSize) => (
                        <option key={pageSize} value={`${pageSize}`}>
                            {pageSize}
                        </option>
                    ))}
                </select>

            </div>
            <div className="whitespace-nowrap text-sm text-muted-foreground">
                showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} to {
                    table.getState().pagination.pageIndex + 1 === table.getPageCount() ?
                        table.getState().pagination.pageIndex * table.getState().pagination.pageSize + table.getFilteredRowModel()?.rows?.length :
                        (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize
                }
            </div>
            <div className="flex flex-col items-center gap-4 sm:flex-row sm:gap-6 lg:gap-8">
                <div className="flex items-center space-x-2">
                    <label htmlFor="gotoPages" className="whitespace-nowrap text-sm font-medium">Go to Page</label>
                    <select 
                        id='gotoPages' 
                        className="h-8 w-14 border-2 rounded-sm"
                        onChange={(event) => {
                            table.setPageIndex(Number(event.target.value) - 1)
                        }}
                    >
                        {pageArray.map((pageIndex) => (
                                <option key={pageIndex} value={`${pageIndex + 1}`}>
                                    {pageIndex + 1}
                                </option>
                            ))}
                    </select>
                </div>

                <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                    Page {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount()}
                </div>
                <ul className="flex items-center space-x-2">
                    <li>
                    <Button
                        aria-label="Go to first page"
                        variant="outline"
                        className="h-8 px-2 gap-2 lg:flex"
                        onClick={handleFirstPageClick}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <DoubleArrowLeftIcon className="h-4 w-4" aria-hidden="true" /> First
                    </Button>
                    </li>
                    <li>
                    <Button
                        aria-label="Go to previous page"
                        variant="outline"
                        className="h-8 px-2 gap-2 lg:flex"
                        onClick={handlePreviousPageClick}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" /> Previous
                    </Button>
                    </li>
                    <li>
                    <Button
                        aria-label="Go to next page"
                        variant="outline"
                        className="h-8 px-2 gap-2 lg:flex"
                        onClick={handleNextPageClick}
                        disabled={!table.getCanNextPage()}
                    >
                      Next <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                    </Button>
                    </li>
                    <li>
                    <Button
                        aria-label="Go to last page"
                        variant="outline"
                        className="h-8 px-2 gap-2 lg:flex"
                        onClick={handleLastPageClick}
                        disabled={!table.getCanNextPage()}
                    >
                    Last <DoubleArrowRightIcon className="h-4 w-4" aria-hidden="true" />
                    </Button>
                    </li>                    
                </ul>
            </div>
        </div>
    )
}