import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import {
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { useDebounce } from "hooks/useDebounce";
import { Cell, Column, Row, Table, TableBody, TableHeader } from 'react-aria-components';
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { DataTablePagination } from "./data-table-pagination";
import { noRecordFound } from "util/helpers";
import { DataTableToolbar } from "./data-table-toolbar";
import { Button } from "components/Buttonv2";

const DataTablev2 = ({
    title,
    columns,
    search,
    generateInvBtn,
    addUserButton,
    addCustomUserButton,
    data,
    totalRecords,
    filterableColumns = [],
    searchableColumns = [],
    tablePagination = true,
    advancedFilter = false
}) => {

    const navigate = useNavigate()

    const location = useLocation()

    const pathname = location.pathname;

    let [searchParams] = useSearchParams()

    const page = searchParams?.get("page") ?? "1"

    const pageAsNumber = Number(page)

    const fallbackPage = isNaN(pageAsNumber) || pageAsNumber < 1 ? 1 : pageAsNumber

    const per_page = searchParams?.get("per_page") ?? "10"

    const perPageAsNumber = Number(per_page)

    const fallbackPerPage = isNaN(perPageAsNumber) || perPageAsNumber < 10 ? 10 : perPageAsNumber

    const sort = searchParams?.get("sort")

    const [column, order] = sort?.split(".") ?? []

    // Create query string
    const createQueryString = useCallback(
        (params) => {
            const newSearchParams = searchParams

            for (const [key, value] of Object.entries(params)) {
                if (value === null || value.length === 0 || value === "null" || value === "undefined") {
                    newSearchParams.delete(key)
                } else {
                    newSearchParams.set(key, String(value))
                }
            }

            return newSearchParams.toString()
        },
        [searchParams]
    )

    const [rowSelection, setRowSelection] = useState({})

    const [columnVisibility, setColumnVisibility] = useState({})

    const [columnFilters, setColumnFilters] = useState([])

    const [{ pageIndex, pageSize }, setPagination] = useState({
        pageIndex: fallbackPage - 1,
        pageSize: fallbackPerPage
    })

    const pagination = useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize])


    useEffect(() => {
        setTimeout(() => {
            const tdElements = document.querySelectorAll('td[role="rowheader"]');
            tdElements.forEach(td => {
              td.setAttribute('role', 'cell');
            });
          }, 1000);
        tablePagination && navigate(`${pathname}?${createQueryString({
            page: pageIndex + 1,
            per_page: pageSize
        })}`, {
            preventScrollReset: false
        })
    }, [createQueryString, navigate, pageIndex, pageSize, tablePagination])

    const [sorting, setSorting] = useState([{
        id: column ?? "",
        desc: order === "desc"
    }])

    //Handle sorting
    useEffect(() => {
        tablePagination && navigate(`${pathname}?${createQueryString({
            page,
            sort: sorting[0]?.id
                ? `${sorting[0]?.id}.${sorting[0]?.desc ? "desc" : "asc"}`
                : null,
        })}`)
    }, [createQueryString, navigate, page, pathname, sorting, tablePagination])

    // Handle server-side filtering
    const debouncedSearchableColumnFilters = JSON.parse(
        useDebounce(
            JSON.stringify(
                columnFilters.filter((filter) => {
                    return searchableColumns.find((column) => column.id === filter.id)
                })
            ),
            500
        )
    )

    const filterableColumnFilters = columnFilters.filter((filter) => {
        return filterableColumns.find((column) => column.id === filter.id)
    })

    useEffect(() => {
        for (const column of debouncedSearchableColumnFilters) {
            if (typeof column.value === "string") {
                navigate(
                    `${pathname}?${createQueryString({
                        page: 1,
                        [column.id]: typeof column.value === "string" ? column.value : null,
                    })}`
                )
            }
        }

        for (const key of searchParams.keys()) {
            if (
                searchableColumns.find((column) => column.id === key) &&
                !debouncedSearchableColumnFilters.find((column) => column.id === key)
            ) {
                navigate(
                    `${pathname}?${createQueryString({
                        page: 1,
                        [key]: null,
                    })}`
                )
            }
        }
    }, [createQueryString, debouncedSearchableColumnFilters, navigate, pathname, searchParams])


    useEffect(() => {
        for (const column of filterableColumnFilters) {
            if (typeof column.value === "object" && Array.isArray(column.value)) {
                navigate(
                    `${pathname}?${createQueryString({
                        page: 1,
                        [column.id]: column.value.join("."),
                    })}`
                )
            }
        }

        for (const key of searchParams.keys()) {
            if (
                filterableColumns.find((column) => column.id === key) &&
                !filterableColumnFilters.find((column) => column.id === key)
            ) {
                navigate(
                    `${pathname}?${createQueryString({
                        page: 1,
                        [key]: null,
                    })}`
                )
            }
        }
    }, [createQueryString, filterableColumnFilters, filterableColumns, navigate, pathname, searchParams])

    const table = useReactTable({
        data,
        columns,
        pageCount: Math.ceil(totalRecords / pageSize),
        state: {
            pagination,
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        manualPagination: true,
        manualSorting: true,
        manualFiltering: true,
    })

    return (
        <div className="w-full flex-1">
            <DataTableToolbar table={table} searchableColumns={searchableColumns} title={title} search={search} addUserButton={addUserButton} generateInvBtn={generateInvBtn} addCustomUserButton={addCustomUserButton} />
            {
                data ? (
                    <div className="overflow-x-auto">
                        <Table aria-label={title} sortDescriptor={
                            sorting.length
                                ? {
                                    column: sorting[0].id,
                                    direction: sorting[0].desc ? "descending" : "ascending",
                                }
                                : undefined
                        }
                            onSortChange={(sortDescriptor) => {
                                table.setSorting([
                                    {
                                        id: sortDescriptor.column,
                                        desc: sortDescriptor.direction === "descending",
                                    },
                                ]);
                            }}
                            className="w-full caption-bottom text-sm relative"

                        >
                            <TableHeader className="[&_tr]:border-b sticky top-0 bg-accent">
                                {
                                    table.getFlatHeaders().map((header) => (
                                        <TableColumn isRowHeader key={header.id} id={header.id} allowsSorting={header.column.getCanSort()} tablePagination={tablePagination}
                                            style={{
                                                width: header.getSize() || 'auto'
                                            }}
                                        >
                                            {
                                                header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())
                                            }
                                        </TableColumn>
                                    ))
                                }
                            </TableHeader>

                            <TableBody className="[&_tr:last-child]:border-0" >
                                {
                                    table.getRowModel().rows.map((row) => (
                                        <TableRow key={row.id} data-state={row.getIsSelected() && "selected"} id={row.id} tabIndex="-1"
                                        >
                                            {
                                                row.getVisibleCells().map(cell => (
                                                    <TableCell key={cell.id} textValue={cell.getValue()} id={cell.id} tabIndex="-1">
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                        {
                            tablePagination && (
                                <DataTablePagination table={table} />
                            )
                        }
                    </div>

                ) : (<div className="no-record-main">
                    <div className="no-record">{noRecordFound}</div>
                </div>)
            }
        </div>
    )
}

export default DataTablev2;

function TableColumn(props) {
    const { tablePagination } = props;

    return (
        <Column className="!p-2" {...props} tabIndex="-1">
            {
                ({sortDirection}) => {
                   return ( <Button variant="ghost" className="button-wrapper flex flex-1 w-full justify-between">
                        {props.children}
                        {tablePagination && (
                            <>
                                {sortDirection === 'ascending' && <ArrowUp className="button-icon w-5 h-5" /> }
                                {sortDirection === 'descending' && <ArrowDown  className="button-icon w-5 h-5" /> }
                                {sortDirection !== 'ascending' && sortDirection !== 'descending' && <ArrowUpDown  className="button-icon w-5 h-5" /> }
                            </>
                        )}
                    </Button>
                   )
                }
            }
        </Column>
    );
}

function TableRow(props) {
    return (
        <Row
            {...props}
            className="border-b transition-colors outline-none data-[focus-visible]:outline-black data-[focus-visible]:-outline-offset-2 cursor-default data-[hovered]:bg-gray-100/50 aria-selected:bg-gray-100 data-[state=selected]:bg-muted"
        />
    );
}

function TableCell(props) {
    return (
        <Cell
        tabIndex="-1"
            {...props}
            className="py-3 px-4 cursor-auto whitespace-nowrap outline-none data-[focus-visible]:outline-black data-[focus-visible]:-outline-offset-2"
        />
    );
}



