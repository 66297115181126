import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'components/Buttonv2';
import { addComment } from 'redux/asyncApi/orderApi';
import { getFirstAndLast } from 'util/helpers';

const CommentBox = ({data}) => {
    const { comments, loggedin } = data;
    const [comment, setComment] = useState('');
    const [commentList, setCommentList] = useState([])
    
    const { token } = useSelector( (state) => state.app.userAuth );
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() =>{
        if(comments) {
            setCommentList(comments)
        }
    },[]);

    const onInputChange = (event) => {
        setComment(event.target.value)
    };

    const onAddComment = () => {
        const newComment = {
            comment : comment,
            user_role: 'user',
            username: loggedin,
            comment_date: moment().format('DD-MMM-YYYY hh:mm:ss A')
        }
        setCommentList([...commentList, newComment]);
        setComment("")
        dispatch(addComment({ order_id: params?.id, comments: comment, token: token }));
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter" && comment.length > 0) {
            onAddComment();
        }
    }

    let conversation;

    if(commentList.length > 0) {
        conversation = commentList.map((comment, index) => {
            const {user_role, comment: commentText, comment_date, username, profileimage} = comment
            const lastName = username.split(" ")[1];

            return (
                <div className={`${user_role === 'user' ? 'flex justify-end' : 'flex justify-start'}`} key={index} >
                    <div className={`w-fit rounded-md border my-2 p-2 
                        ${ user_role === 'user' ? "bg-primary text-primary-foreground" :"bg-white"} `}>
                        <div className='text-xs flex items-center justify-start'>
                            {profileimage && (
                                <img
                                    src={profileimage}
                                    className='h-5 w-5 rounded-full bg-gray-300 flex items-center justify-center mr-1'
                                    alt={""}
                                />
                            )}
                            {!profileimage && (
                                <span className='h-5 w-5 rounded-full bg-gray-300 flex items-center justify-center mr-1 text-black font-semibold'>
                                    {getFirstAndLast(username, lastName)}
                                </span>
                            )}
                            <span>{username}</span>
                        </div>
                        <div className='text-base'>{commentText}</div>
                        <div className='text-xs text-right'>{ moment(comment_date, 'DD-MMM-YYYY hh:mm:ss A').fromNow()}</div>
                    </div>
                </div> 
            );                      
        })
    } else {
        conversation =  (
        <div className='flex justify-center items-center text-base h-full w-full'>
            No comments added
        </div>
        )
    }      

    return (
        <>
            <div className="bg-white rounded-md border shadow-md my-4 divide-y">
                <h2 className='text-2xl font-bold mb-0 px-3 py-1.5'>Chat with Support</h2>

                <div className='px-3 bg-accent h-52 overflow-auto'>
                    {conversation}
                </div>

                <div className='h-16 px-3 flex items-center'>
                    <input
                        type="text"
                        aria-label="Enter new comment"
                        placeholder='Enter new comment'
                        className="placeholder:text-muted-foreground focus-visible:outline-none p-2 flex-1 h-9 rounded-md box-border border-2 w-full"
                        value={comment}
                        onChange={onInputChange}
                        onKeyDown={(e) => {onKeyDown(e)}}
                    />
                    
                    <Button
                        title={"Add Comment"}
                        className={'ms-3'}
                        variant="default"
                        onClick={() => onAddComment()}
                        disabled={comment.length === 0}
                        >
                        Add Comment
                    </Button>
                </div>
            </div>
        </>
    );
}

export default CommentBox;
