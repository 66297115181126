import React, { useEffect, useState } from 'react';

const Status = {
    Idle: 'idle',
    Loading: 'loading',
    Error: 'error',
    Success: 'success',
}

const Avatar = ({ imageSrc, name }) => {
    const [status, setStatus] = useState(imageSrc ? Status.Loading : Status.Idle);
    const initials = name
        ?.split(' ')
        .map((chunk) => chunk.charAt(0).toLocaleUpperCase())
        .slice(0, 2)
        .join('');

    useEffect(() => {
        if (imageSrc) {
            setStatus(Status.Loading);

            // Test if the image can be loaded successfully by creating a non-rendered Image element
            // and adding event listeners for a "load" or "error"
            const img = new Image();

            // If the image is loaded successfully, we'll render it
            img.onload = () => {
                setStatus(Status.Success);
            };

            // Otherwise, we'll show the initials
            img.onerror = () => {
                setStatus(Status.Error);
            };

            // Now that the event handlers have been added, set the source to initiate the image load
            img.src = imageSrc;
        }
    }, [imageSrc]);
    const isLoading = status === Status.Loading;
    const hasLoadedImage = status === Status.Success;

    return (
        <div
            style={{
                height: 40,
                width: 40,
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                fontWeight: 'bold',
            }}
            className='bg-gray-200'
        >
            {imageSrc && (isLoading || hasLoadedImage) && (
                <img
                    alt={name}
                    src={imageSrc}
                    style={{
                        display: isLoading ? 'none' : 'block',
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover'
                    }}
                />
            )}

            {!hasLoadedImage && !isLoading && <span>{initials}</span>}
        </div>
    );
};

export default Avatar;