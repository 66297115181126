// import React, { useCallback, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import DataTablesComp from "components/dataTable";
// import { confirmedFiles } from "redux/Slices/uploadSlice";
// import { removeFile } from "redux/asyncApi/uploadApi";
// import { useNavigate } from "react-router-dom";
// import { ROUTES } from "routes/constant";
// import moment from "moment";
// import { Trash3 } from "assets/images";
// import ConfirmPopUp from "components/Confirm/ConfirmPopUp";
// import DataTablev2 from "components/dataTablev2";
// import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectSeparator, SelectTrigger, SelectValue } from "components/SelectV2";
// import { Button } from "components/Buttonv2";

// const ConfirmFileTable = ({ data, success, token }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { loading } = useSelector((state) => state.app) || {};
//   const { fileTypes, selectedUser } =
//     useSelector((state) => state.upload) || {};
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [id, setId] = useState({});

//   // Modal Constants
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   // const cellRendrer = useCallback(
//   //   (cell, row, column, index, tHeadId) => {
//   //     if (column === "original_file_name") {
//   //       return (
//   //         <div id={row.id} className="file_name_head">
//   //           {row?.original_file_name}
//   //         </div>
//   //       );
//   //     }

//   //     if (column === "file_page_count") {
//   //       return (
//   //         <div id={row.id}>
//   //           <span className="role">{row?.file_page_count}</span>
//   //         </div>
//   //       );
//   //     }
//   //     // if (column === "file_deliver_date") {
//   //     //   return (
//   //     //     <div id={row.id}>
//   //     //       <span className="role">
//   //     //         {moment(row?.file_deliver_date).format("MMM DD, YYYY")}
//   //     //       </span>
//   //     //     </div>
//   //     //   );
//   //     // }
//   //     if (column === "file_type") {
//   //       const onSelectChangeHandler = (e) => {
//   //         const { value } = e.target;
//   //         const result = data.map((element) => {
//   //           if (element.id === row.id) {
//   //             return {
//   //               ...element,
//   //               file_type: value,
//   //             };
//   //           } else {
//   //             return element;
//   //           }
//   //         });
//   //         dispatch(confirmedFiles(result));
//   //       };

//   //       const reqFileId = "req_file"+row?.id;

//   //       return (
//   //         <div id={row.id}>
//   //           <span className="role">
//   //             <select
//   //               id={reqFileId}
//   //               className="form-select"
//   //               aria-labelledby={`${tHeadId} ${reqFileId} file_type_sort`}
//   //               value={row.file_type}
//   //               onChange={onSelectChangeHandler}
//   //             >
//   //               {row?.original?.filetype.map((type, index) => (
//   //                 <option key={type.id} value={type.label_text}>
//   //                   {type.label_text}
//   //                 </option>
//   //               ))}
//   //             </select>
//   //           </span>
//   //         </div>
//   //       );
//   //     }
//   //     if (column === "action") {
//   //       const remDelId="rem_del"+row?.id;
//   //       return (
//   //         <div>
//   //           <button
//   //             id={remDelId}
//   //             className="trashicon"
//   //             aria-labelledby={`${tHeadId} ${remDelId} action`}
//   //             onClick={() =>
//   //               setId({
//   //                 id: row?.id,
//   //                 order_temp_id: row?.order_temp_id,
//   //               }) & handleShow()
//   //             }
//   //           >
//   //             <Trash3 id={row.doc_name} role="img" aria-label="Delete" />
//   //           </button>
//   //         </div>
//   //       );
//   //     }

//   //     return row[column];
//   //   },
//   //   [data]
//   // );

//   // const tableHead = [
//   //   {
//   //     key: "original_file_name",
//   //     title: "FILE NAME",
//   //     sorting: true,
//   //     searching: true,
//   //     width: "40%",
//   //     theadId: "original_file_name",
//   //   },
//   //   {
//   //     key: "file_page_count",
//   //     title: "PAGES",
//   //     sorting: true,
//   //     searching: false,
//   //     width: "20%",
//   //     theadId: "page_cout",
//   //   },
//   //   // {
//   //   //   key: "file_deliver_date",
//   //   //   title: "Est. Date of Delivery",
//   //   //   sorting: true,
//   //   //   searching: false,
//   //   //   width: "20%",
//   //   // },
//   //   {
//   //     key: "file_type",
//   //     title: "REQUIRED FILE TYPE",
//   //     sorting: true,
//   //     searching: false,
//   //     width: "12%",
//   //     theadId: "file_type_sort",
//   //   },
//   //   {
//   //     key: "action",
//   //     title: "ACTIONS",
//   //     sorting: false,
//   //     searching: false,
//   //     width: "8%",
//   //     theadId: "action",
//   //   },
//   // ];

//   const onConfirmHandler = () => {
//     handleClose();
//     const values = {
//       ...id,
//       user_id: selectedUser?.value,
//     };
//     dispatch(removeFile({ token, values }));
//   };

//   const columns = [
//     {
//       id: "original_file_name",
//       accessorKey: "original_file_name",
//       header: "File Name",
//       cell: ({ row }) => {
//         return (
//           <div id={row?.original?.id} className="file_name_head">
//             {row?.original?.original_file_name}
//           </div>
//         );
//       }
//     },
//     {
//       accessorKey: "file_page_count",
//       header: "Pages",
//       cell: ({ row }) => {
//         return (
//           <div id={row?.original?.id}>
//             <span className="role">{row?.original?.file_page_count}</span>
//           </div>
//         );
//       }
//     },
//     {
//       accessorKey: "file_type",
//       header: "Required File Type",
//       cell: ({ row }) => {
//         const onSelectChangeHandler = (e) => {
//           const { value } = e.target;
//           const result = data.map((element) => {
//             if (element.id === row?.original?.id) {
//               return {
//                 ...element,
//                 file_type: value,
//               };
//             } else {
//               return element;
//             }
//           });
//           dispatch(confirmedFiles(result));
//           setTimeout(() => {
//             const selectElement = document.getElementById("select_" + reqFileId);
//             if (selectElement) {
//               selectElement.focus();
//             }
//           }, 500);
//         };

//         const reqFileId = "req_file" + row?.original?.id;

//         return (
//           <div id={row?.original?.id} className="w-1/2">
//             <Select onValueChange={(value) => {
//               onSelectChangeHandler({ target: { value } })
//             }}
//               defaultValue={row?.original?.file_type}
//             >
//               <SelectTrigger id={"select_" + reqFileId}>
//                 <SelectValue id={reqFileId}>
//                   {row?.original?.filetype.find((type) => type.label_text === row?.original?.file_type.toUpperCase())?.label_text}
//                 </SelectValue>
//               </SelectTrigger>
//               <SelectContent>
//                 <SelectGroup>
//                   <SelectLabel>File Type</SelectLabel>
//                   {row?.original?.filetype.map((type, index) => (
//                     <SelectItem key={type.id} value={type.label_text}>
//                       {type.label_text}
//                     </SelectItem>
//                   ))}
//                 </SelectGroup>
//               </SelectContent>
//             </Select>
//             {/* 
//               <select
//                 id={reqFileId}
//                 className="form-select"
//                 aria-labelledby={`${reqFileId} file_type_sort`}
//                 value={row?.original.file_type}
//                 onChange={onSelectChangeHandler}
//               >
//                 {row?.original?.filetype.map((type, index) => (
//                   <option key={type.id} value={type.label_text}>
//                     {type.label_text}
//                   </option>
//                 ))}
//               </select> */}
//           </div>
//         );
//       }
//     },
//     {
//       accessorKey: 'id',
//       header: "ACTIONS",
//       cell: ({ row }) => {
//         const remDelId = "rem_del" + row?.original?.id;
//         return (
//           <div>
//             <Button
//               id={remDelId}
//               variant="destructive"
//               // className="trashicon"
//               // aria-labelledby={`${remDelId} action`}
//               aria-label='Delete File'
//               onClick={() =>
//                 setId({
//                   id: row?.original?.id,
//                   order_temp_id: row?.original?.order_temp_id,
//                 }) & handleShow()
//               }
//             >
//               <Trash3 id={row?.original?.doc_name} role="img" aria-label="Delete" />
//             </Button>
//           </div>
//         );
//       }
//     },
//   ];

//   return (
//     <>
//       <div className="dt-responsive border-table">
//         {/* {!loading && success && data?.length > 0 && ( */}
//         {/* <DataTablesComp
//             tableHead={tableHead}
//             data={data}
//             renderCell={cellRendrer}
//             setCurrentPage={setCurrentPage}
//             currentPage={currentPage}
//             setPageSize={setPageSize}
//             pageSize={pageSize}
//             type="order"
//             search={false}
//             pageSizing={true}
//             paginationBar={true}
//             // pageSize={true}
//             tableDescription="Confirm File List"
//           /> */}

//         <DataTablev2
//           data={data ? data : []}
//           totalRecords={data?.length}
//           columns={columns}
//           search={false}
//           title={"Confirm File List"}
//           tablePagination={false}
//         />

//         {/* )} */}
//         {/* {(!loading && !success) ||
//           (data?.length <= 0 && (
//             <div className="no-record-main">
//               <div className="no-record">No Record Found</div>
//             </div>
//           ))} */}
//       </div>
//       <ConfirmPopUp
//         Icon={Trash3}
//         heading="Delete file"
//         confirmMsg="You are about to delete a file.Are you sure that you want to
//         delete this file?"
//         show={show}
//         handleClose={handleClose}
//         onConfirmHandler={onConfirmHandler}
//         id={id.id}
//       />
//     </>
//   );
// };
// export default ConfirmFileTable;


import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmedFiles } from "redux/Slices/uploadSlice";
import { removeFile } from "redux/asyncApi/uploadApi";
import { Trash3 } from "assets/images";
import ConfirmPopUp from "components/Confirm/ConfirmPopUp";
import { Button } from "components/Buttonv2";

const ConfirmFileTable = ({ data, success, token }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.app) || {};
  const { selectedUser } = useSelector((state) => state.upload) || {};
  const [id, setId] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onConfirmHandler = () => {
    handleClose();
    const values = {
      ...id,
      user_id: selectedUser?.value,
    };
    dispatch(removeFile({ token, values }));
  };

  const handleFileTypeChange = (value, fileId) => {
    const updatedData = data.map((file) =>
      file.id === fileId ? { ...file, file_type: value } : file
    );
    dispatch(confirmedFiles(updatedData));
  };

  const handleDelete = (fileId) => {
    setId({ id: fileId });
    handleShow();
  };

  return (
    <>
    <div className="flex w-full items-center justify-end space-x-2 p-3">
      <div className="flex flex-row items-center flex-wrap justify-between w-full">
        <h2 className="text-2xl font-bold mb-0 py-1.5 px-2" id="table_info">Confirm File List</h2>
        <div className="flex flex-row items-center gap-2"></div>
      </div>
    </div>
    <div className="dt-responsive border-table">
      <div className="w-full flex-1">
      <table className="w-full caption-bottom text-sm relative">
        <thead className="[&_tr]:border-b sticky top-0 bg-accent">
          <tr>
            <th scope="col" className="!p-2"><p className="items-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 flex flex-1 w-full justify-between">File Name</p></th>
            <th scope="col" className="!p-2"><p className="items-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 flex flex-1 w-full justify-between">Pages</p></th>
            <th scope="col" className="!p-2"><p className="items-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 flex flex-1 w-full justify-between">Required File Type</p></th>
            <th scope="col" className="!p-2"><p className="items-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 flex flex-1 w-full justify-between">Actions</p></th>
          </tr>
        </thead>
        <tbody className="[&_tr:last-child]:border-0">
          {data.map((file) => (
            <tr key={file.id} className="border-b transition-colors outline-none data-[focus-visible]:outline-black data-[focus-visible]:-outline-offset-2 cursor-default data-[hovered]:bg-gray-100/50 aria-selected:bg-gray-100 data-[state=selected]:bg-muted">
              <th scope="row" className="row_th py-3 px-4 cursor-auto whitespace-nowrap outline-none data-[focus-visible]:outline-black data-[focus-visible]:-outline-offset-2">{file.original_file_name}</th>
              <td className="py-3 px-4 cursor-auto whitespace-nowrap outline-none data-[focus-visible]:outline-black data-[focus-visible]:-outline-offset-2">{file.file_page_count}</td>
              <td className="py-3 px-4 cursor-auto whitespace-nowrap outline-none data-[focus-visible]:outline-black data-[focus-visible]:-outline-offset-2">
              <div className="w-1/2">
                <select
                  id={`select_${file.id}`}
                  className="flex h-9 w-full items-center justify-between rounded-md border border-input bg-white-900 px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 selectfocus"
                  value={file.file_type}
                  onChange={(e) => handleFileTypeChange(e.target.value, file.id)}
                  aria-label={`Select file type for ${file.original_file_name}`}
                >
                  {file.filetype.map((type) => (
                    <option key={type.id} value={type.label_text}>
                      {type.label_text}
                    </option>
                  ))}
                </select>
              </div>
              </td>
              <td className="py-3 px-4 cursor-auto whitespace-nowrap outline-none data-[focus-visible]:outline-black data-[focus-visible]:-outline-offset-2">
                <Button
                  id={`rem_del${file.id}`}
                  variant="destructive"
                  onClick={() => handleDelete(file.id)}
                  aria-label={`Delete file ${file.original_file_name}`}
                >
                  <Trash3 />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
      <ConfirmPopUp
        Icon={Trash3}
        heading="Delete file"
        confirmMsg="You are about to delete a file. Are you sure that you want to delete this file?"
        show={show}
        handleClose={handleClose}
        onConfirmHandler={onConfirmHandler}
        id={id.id}
      />
    </>
  ); 
};

export default ConfirmFileTable;

