import React from "react";
import FocusTrap from "focus-trap-react";
import { Clear } from "assets/images";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/Dialog";

const CustomModal = ({
  children,
  show,
  handleClose,
  modalHeading,
  className,
  hideHeader,
}) => {
  return (
    <Dialog
      aria-labelledby="contained-modal-title-vcenter"
      centered
      open={show}
      modal
      onOpenChange={handleClose}
      // backdrop={true}
      className={className}
    >
      {/* <FocusTrap active={show}> */}
      <DialogContent>
        {!hideHeader && (
          <DialogHeader>
            <DialogTitle
              className="flex items-center dialog_focus"
              id="contained-modal-title-vcenter"
              tabIndex="-1"
            >
              {modalHeading}
            </DialogTitle>
          </DialogHeader>
        )}
        {children}
      </DialogContent>
      {/* </FocusTrap> */}
    </Dialog>
  );
};

export default CustomModal;
